import React from "react";
import { Logo } from "@app/styles/logo";
import { NavWrapper } from "./styles";
import { useNavigate, useSearchParams} from "react-router-dom";

const FEATURE_FLAGS = {
  SUPPORT_FOCUS_CHAT: 'support-focus-chat'
}
const Nav = () => {
  const [searchParams, setSearchParams ] = useSearchParams()
  const supportFocusChat = Boolean(searchParams.get(FEATURE_FLAGS.SUPPORT_FOCUS_CHAT))
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/kchat");
  };
  const gotoFocusChat = () => {
    navigate("/kchat?support-focus-chat=true");
  }
  return (
    <NavWrapper className="d-flex align-items-center  border-bottom justify-content-between">
      <div className="d-flex align-items-center pl-3 justify-content-between">
        <Logo onClick={onClick} />
      </div>
      <div className="d-flex justify-content-end pr-3">
        { !supportFocusChat && <button type="button" className="btn btn-outline-primary btn-sm" onClick={gotoFocusChat}>
          <span className="mr-1"> Go Focus Chat </span>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-left"
            className="svg-inline--fa fa-arrow-left rotate-180 "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            width={'12'}
            style={{transform: 'rotate(180deg)'}}
          >
            <path
              fill="currentColor"
              d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </button>}
      </div>
    </NavWrapper>
  );
};

export default Nav;
