import React, { useEffect, useState } from "react";
import Nav from "@app/components/nav";
import { Routes, Route } from "react-router-dom";
const App = () => {
  return (
    <Routes>
      <Route path="*" element={<Nav />} />
    </Routes>
  );
};
export default App;
