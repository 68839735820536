import React from "react";
import styled from "styled-components";
import logo from "@app/styles/assets/kbrain_logo.png";

export const LogoImg = styled.img`
  width: 22px;
  height: 22px;
`;

export const LogoText = styled.span`
  font-family: Lato;
  font-size: 19px;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
`;

export const TextLink = styled.a`
  font-family: Lato;
  font-size: 19px;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
  color: black;
  &:hover {
    text-decoration: none;
    color: black;
  }
`;
export const Logo = ({ onClick }) => {
  return (
    <TextLink
      className="d-flex align-items-center"
      href={"/kchat"}
      style={{ cursor: "pointer" }}
    >
      {/* <LogoImg className={"mr-2"} src={logo} /> */}
      <LogoText>KBRaiN&#x2122;</LogoText>
    </TextLink>
  );
};
