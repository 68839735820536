import styled from "styled-components"
import { COLOR } from "@app/styles"
export const NavWrapper = styled.div`
    height: 54px;
    position: fixed;
    z-index: 999;
    width: 100%;
    >div:first-child{
        width: 235px;
        background-color: #fff;
        box-sizing: border-box;
    }
    >div:nth-child(2) {
        width: 235px;
        background-color: #fff;
    }
    
`